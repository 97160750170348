// Newrelic config for NREUM
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NREUMConfig = {} as any;

NREUMConfig.init = {
  distributed_tracing: { enabled: true },
  privacy: { cookies_enabled: true },
  ajax: { deny_list: ['bam.nr-data.net'] },
};

NREUMConfig.loader_config = {
  agentID: import.meta.env.VITE_NR_APP_ID,
  licenseKey: import.meta.env.VITE_NR_LICENSE_KEY,
  applicationID: import.meta.env.VITE_NR_APP_ID,
};
NREUMConfig.info = {
  beacon: 'bam.nr-data.net',
  errorBeacon: 'bam.nr-data.net',
  licenseKey: import.meta.env.VITE_NR_LICENSE_KEY,
  applicationID: import.meta.env.VITE_NR_APP_ID,
  sa: 1,
};

export default NREUMConfig;
